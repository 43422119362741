<template>
  <data-list
    ref="dataList"
    class="content"
    :url="url"
    :query="query"
    :default-query="defaultQuery"
    entity-name="入库清单"
    hide-show-detail
    show-row-num
    :rules="rules"
    :columns="columns"
    :search-key-type="searchKeyType"
    :before-edit="beforeEdit"
    :before-update="beforeUpdate"
    :requestConfig="{ noTempleFilter: true }"
    :action="action"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="交货人">
        <select-book type="user" v-model="form.delivered_by" @change="updateList"></select-book>
      </a-form-model-item>
      <a-form-model-item>
        <a-select
          style="width: 180px;"
          placeholder="选择入库类别查询"
          @change="updateList"
          :options="warehousingOptions"
          v-model="form.category"
          allowClear
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-input v-model="form.goods_name" placeholder="输入商品名称/SKU查询" :maxLength="50" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-range-picker
          v-model="form.warehouse_date"
          :placeholder="['入库开始日期', '入库截止日期']"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          allowClear
          @change="updateList"
          class="range-picker"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-input v-model="form.notes" placeholder="输入备注查询" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:button>
      <a-button type="primary" @click="exportData" :loading="exporting">
        {{ exporting ? "正在导出" : "导出EXCEL" }}
      </a-button>
      <upload-file uploadText="导入清单" type="primary" :url="`${url}/import`" @change="updateList"></upload-file>
      <a-button @click="downloadTemplate">下载模板</a-button>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="入库类别" prop="category">
        <a-select placeholder="选择入库类别" :options="warehousingOptions" v-model="form.category"></a-select>
      </a-form-model-item>
      <a-form-model-item label="入库日期" prop="warehouse_date" extra="仅允许选择当前及之前的日期">
        <a-date-picker
          v-model="form.warehouse_date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="选择日期"
          allowClear
          style="width: 100%;"
          :disabled-date="disabledDate"
        />
      </a-form-model-item>
      <a-form-model-item label="商品名称" prop="goods_id">
        <GoodsSelect v-model="form.goods_id" :form="form" :options="goodsOptions" />
      </a-form-model-item>
      <a-form-model-item label="入库数量" prop="num">
        <a-input-number
          v-model="form.num"
          placeholder="请输入数字"
          :min="1"
          :step="1"
          allowClear
          style="width: 100%;"
          @change="updateTotalAmount($event, form)"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="商品单价（元）">
        <a-input-number
          v-model="form.unit_price"
          placeholder="请输入"
          :min="0"
          :step="0.01"
          allowClear
          style="width: 100%;"
          @change="updateTotalAmount($event, form)"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="合计金额（元）">
        <a-input-number
          v-model="form.price"
          placeholder="请输入"
          :min="0"
          :step="0.01"
          allowClear
          style="width: 100%;"
          disabled
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="交货人">
        <select-book type="user" v-model="form.delivered_by"></select-book>
      </a-form-model-item>
      <a-form-model-item label="备注">
        <a-textarea
          v-model="form.notes"
          placeholder="填写入库备注"
          :autoSize="{ minRows: 2, maxRows: 6 }"
          :maxLength="200"
          allowClear
        ></a-textarea>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import moment from "moment";
import {
  clone,
  downLoadBlobFile,
  downLoadFile,
  getKeyTitle,
  getQuery,
  isEmpty,
  stringConvertTimestamp,
  timestampConvertString,
} from "../../common/js/tool";
import { warehousingOptions } from "../../common/asset/type";
import { warehousingTemplateUrl } from "../../common/asset/template";
import { getTemple } from "../../common/js/storage";
import GoodsSelect from "./components/GoodsSelect.vue";

export default {
  name: "WarehousingList",
  components: {
    GoodsSelect,
  },
  data() {
    return {
      url: "/admin/asset-warehouse",
      defaultQuery: {
        category: 1,
      },
      searchKeyType: {
        delivered_by: 2,
        category: 2,
        goods_name: 2,
        warehouse_date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value?.length > 0) {
            res = `&start_date=${stringConvertTimestamp(value[0])}&end_date=${stringConvertTimestamp(value[1])}`;
          }
          return res;
        },
        notes: 2,
      },
      columns: [
        {
          title: "日期",
          dataIndex: "warehouse_date",
          customRender: (text) => {
            return timestampConvertString(text, "yyyy-MM-dd") || "-";
          },
        },
        {
          title: "入库类别",
          dataIndex: "category",
          customRender: (text) => {
            return <a-tag>{getKeyTitle(warehousingOptions, text, "value", "label")}</a-tag>;
          },
        },
        {
          title: "商品名称",
          customRender: (text) => {
            return text.goods?.name || "-";
          },
        },
        {
          title: "商品单位",
          customRender: (text) => {
            return text.goods?.goodsUnit?.name || "-";
          },
        },
        { title: "数量", dataIndex: "num" },
        {
          title: "交货人",
          dataIndex: "delivered_by",
          customRender: (text) => {
            return text ? <open-data type="userName" openid={text} /> : "-";
          },
        },
        {
          title: "备注",
          dataIndex: "notes",
          customRender: (text) => {
            return isEmpty(text) ? "-" : text;
          },
        },
        {
          title: "附件",
          dataIndex: "annex",
          customRender: (text) => {
            if (text) {
              text = JSON.parse(text);
              return text.map((file) => {
                return <a onclick={() => downLoadFile(file.url, file.name)}>{file.name}</a>;
              });
            } else {
              return "-";
            }
          },
        },
      ],
      rules: {
        category: [{ required: true, message: "请选择入库类别", trigger: "change" }],
        warehouse_date: [{ required: true, message: "请选择入库日期", trigger: "change" }],
        goods_id: [{ required: true, message: "请选择商品", trigger: "change" }],
        num: [{ required: true, message: "请输入入库数量", trigger: "blur" }],
      },
      warehousingOptions,
      exporting: false,

      goodsOptions: [],
    };
  },
  computed: {
    dataList() {
      return this.$refs.dataList;
    },
    query() {
      let temple = getTemple();
      return `&filter[asset_warehouse.temple_id]=${temple}&sort=-warehouse_date,-create_time&expand=goods.goodsUnit`;
    },
    action() {
      return this.$route.meta.action;
    },
  },
  created() {},
  methods: {
    // 更新合计金额
    updateTotalAmount(val, form) {
      if (!isEmpty(form.num) && !isEmpty(form.unit_price)) {
        this.$set(form, "price", form.num * form.unit_price);
      } else {
        this.$set(form, "price", 0);
      }
    },
    beforeEdit(form, fn) {
      if (form.goods_id && form.goods) {
        const goods = form.goods;
        const option = { ...goods, label: goods.name, value: goods.id };
        this.goodsOptions = [option];
      }
      if (form.warehouse_date) {
        form.warehouse_date = timestampConvertString(form.warehouse_date, "yyyy-MM-dd");
      }

      fn(form);
    },
    beforeUpdate(form, fn) {
      let res = clone(form);
      if (res.warehouse_date) {
        res.warehouse_date = stringConvertTimestamp(res.warehouse_date);
      }
      if (res.delivered_by?.length > 0) {
        res.delivered_by = res.delivered_by[0];
      } else {
        form.delivered_by = null;
      }
      fn(res);
    },
    updateList() {
      this.dataList.updateList();
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    downloadTemplate() {
      downLoadFile(warehousingTemplateUrl, "商品模板");
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let temple = getTemple();
      let url = `${this.url}/export?temple_id=${temple}` + getQuery.call(this.dataList, this.searchKeyType);
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, "入库数据");
        })
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style lang="less" scoped></style>
