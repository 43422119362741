// 商品模板地址
export const purchaseTemplateUrl = "https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/template/purchase_new.xlsx";

// 入库模板地址
export const warehousingTemplateUrl =
  "https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/template/warehousing.xlsx";

// 出库模板地址
export const outboundTemplateUrl = "https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/template/outbound.xlsx";

// 固定资产模板地址
export const fixedAssetTemplateUrl = "https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/template/fixed_asset.xlsx";
