<template>
  <a-select
    style="width: 100%;"
    placeholder="输入商品名称查询"
    :options="goodsOptions"
    v-model="goods_id"
    allow-clear
    show-search
    :filter-option="false"
    :not-found-content="fetching ? undefined : null"
    @change="goodsChange"
    @search="goodsSearch"
  >
    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
    <span slot="suffixIcon" style="color: black;" v-if="form.goods && form.goods.goodsUnit">{{
      form.goods.goodsUnit.name
    }}</span>
  </a-select>
</template>

<script>
import { isEmpty, dataConvertOptions, debounce } from "../../../common/js/tool";

export default {
  name: "GoodsSelect",
  props: {
    value: Number, // 商品id
    form: Object,
    options: Array, // 默认选项
  },
  data() {
    this.lastFetchId = 0;
    this.goodsSearch = debounce(this.goodsSearch, 300);
    return {
      fetching: false,
      goodsOptions: [],
    };
  },
  computed: {
    goods_id: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    options() {
      this.setOptions();
    },
  },
  created() {
    this.setOptions();
  },
  methods: {
    setOptions() {
      this.goodsOptions = this.options || [];
    },
    /* 查询商品名称 */
    goodsSearch(keyword) {
      if (isEmpty(keyword)) {
        this.goodsOptions = [];
      } else {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.goodsOptions = [];
        this.fetching = true;
        this.$axios({
          url: "/admin/asset-goods",
          method: "GET",
          params: {
            sort: "-id",
            expand: "goodsUnit",
            page: 1,
            pageSize: 100,
            "filter[name][like]": keyword,
          },
        })
          .then((res) => {
            if (fetchId !== this.lastFetchId) {
              // for fetch callback order
              return;
            }
            this.goodsOptions = dataConvertOptions(res.data);
          })
          .finally(() => {
            this.fetching = false;
          });
      }
    },
    goodsChange(val) {
      const form = this.form;
      if (val) {
        let target = this.goodsOptions.find((item) => item.value === val);
        form.goods = target;
      } else {
        form.goods = null;
      }
      this.$emit("change", val);
    },
  },
};
</script>

<style lang="less" scoped></style>
